import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/particular.css";

function Particular() {
  return (
    <>
      <div className="all_center_div">
        <div className="main-container">
          <Header />
          <div className="contactAll">
            <div className="contactLeftBottomTitle">
              <h1 className="privacy_title_h1">特定商取引法に関する表示</h1>
              <section className="chart">
                <h2 className="ttlShop">店舗情報</h2>
                <table>
                  <tbody>
                    <tr>
                      <th> ショップ名 </th>
                      <td> TANYA UUOO LLC </td>
                    </tr>
                    <tr>
                      <th> 販売業者 </th>
                      <td> 自然工房株式会社 </td>
                    </tr>
                    <tr>
                      <th> 販売責任者 </th>
                      <td> 福山　孝行 </td>
                    </tr>
                    <tr>
                      <th> 所在地 </th>
                      <td>
                        {" "}
                        1309 Coffeen Avenue STE 1200，Sheridan, Wyoming 82801{" "}
                      </td>
                    </tr>
                    <tr>
                      <th> 電話番号 </th>
                      <td> (415) 425-9345 </td>
                    </tr>
                    <tr>
                      <th> FAX番号 </th>
                      <td> (415) 425-9345 </td>
                    </tr>
                    <tr>
                      <th> メールアドレス </th>
                      <td> well@itship.store </td>
                    </tr>
                    <tr>
                      <th> ホームページ </th>
                      <td> www.itship.store </td>
                    </tr>
                    <tr>
                      <th> 取扱商品 </th>
                      <td>
                        天然木、無垢材を使用した国産オーダー家具（TVボード、ダイニングテーブル、ローテーブル、チェスト・たんす、棚、リビング収納・キャビネットなど）。
                      </td>
                    </tr>
                    <tr>
                      <th> 許認可・資格 </th>
                      <td> 協同組合福岡・大川家具工業会 会員 </td>
                    </tr>
                    <tr>
                      <th> 販売数量 </th>
                      <td>
                        ほぼすべての商品が受注生産です。（展示品以外、基本的に在庫はありません。）
                        <br />
                        ご注文いただきましたら、完成予定日～完成のご連絡などお打ち合わせをさせていただきます。
                      </td>
                    </tr>
                    <tr>
                      <th> 商品代金以外の必要料金 </th>
                      <td>
                        全国送料無料（遠方や一部離島を除く）。
                        <br />
                        ※遠方の方や離島の方には別途送料をお願いさせていただく場合もございます。
                        <br />
                        <br />
                        送料、代金引換、銀行振込に関する各手数料は、原則「当社負担」となります。
                        <br />
                        消費税はすべて「商品代金（税込価格）」で表示しています。
                      </td>
                    </tr>
                    <tr>
                      <th> 申込の有効期限 </th>
                      <td>
                        ご注文後、「７日以内」にお支払下さい。
                        <br />
                        なお、ご注文から７日以内にご入金が確認できない場合は、ご注文をキャンセル扱いとさせていただきます。
                      </td>
                    </tr>
                    <tr>
                      <th> 引き渡し時期 </th>
                      <td>
                        受注生産のためご注文～製作終了までおよそ40-60日。
                        <br />
                        その後お届けで約10日ほど。
                        <br />
                        お待たせして申し訳ございません。
                        <br />
                        ご了承のほどお願い申し上げます。
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h2 className="ttlShop"> 返品・不良品について </h2>
                <table>
                  <tbody>
                    <tr>
                      <th> 返品期限・条件 </th>
                      <td>
                        ※送料はお客様ご負担でお願いしております。（不良品、誤品配送の際、送料は当社負担で対応させていただきます。）
                        <br />
                        <br />
                        ※オーダーメイド製品（サイズオーダー、カスタム含む）及び、セール品の返品はご遠慮ください。
                      </td>
                    </tr>
                    <tr>
                      <th> 返品送料 </th>
                      <td>
                        ※お客様都合による返品は、お客様のご負担でお願いしております。
                        <br />
                        不良品、誤品配送の際、送料は当社負担で対応させていただきます。
                      </td>
                    </tr>
                    <tr>
                      <th> 不良品 </th>
                      <td>
                        高額商品（３万円以上）の場合：
                        <br />
                        万一、商品に不具合等ございましたら、一度商品の写真や動画をお撮りいただき、弊社でチェックいたします。その後、不良品と判断させていただいた場合には、商品を一度お預かりして、補修・修復の対象とさせていただきます。この場合による送料等は当社が負担いたします。
                        <br />
                        <br />
                        <br />
                        一般商品・一部商品の場合：
                        <br />
                        万一、不良品等がございましたら、当店の在庫状況を確認のうえ、新品、または同等品と交換させていただきます。
                        <br />
                        商品到着後、「７日以内」にメールまたはお電話でご連絡ください。それを過ぎますと返品交換のご要望はお受けできなくなりますので、ご了承ください。
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />

                <h2 className="ttlShop"> お支払方法について</h2>
                <table>
                  <tbody>
                    <tr>
                      <th>お支払方法</th>
                      <td>
                        銀行振込、郵便振替、クレジットカード決済、コンビニ決済をご用意しております。
                        <br />
                        ご希望にあわせて、各種ご利用ください。
                      </td>
                    </tr>
                    <tr>
                      <th>お支払期限</th>
                      <td>
                        銀行振り込み・郵便振替：前払い
                        <br />
                        クレジット決済：即日
                        <br />
                        コンビニ決済：前払い
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Particular;
